<template>
<header class="header navbar navbar-expand bg-blur border-bottom shadow fixed-top" style="z-index: 10;">
  <div class="container-fluid px-3">
    <router-link to="/" class="navbar-brand p-0 m-0 pe-3">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 80" width="100" height="30" class="logo">
        <text x="0" y="70" class="red-shadow">D1M</text>
        <text x="0" y="70" class="cyan-shadow">D1M</text>
        <text x="0" y="70" class="main-text">D1M</text>
      </svg>
    </router-link>
    <div class="d-flex align-items-center w-100">

      <ul class="navbar-nav d-none d-lg-flex" style="padding-left: 12rem;">
        <li class="nav-item" v-for="item in navItems" :key="item.title">
          <router-link :to="item.url" class="nav-link">
            <i :class="item.iconClass"></i>
            {{ item.title }}
          </router-link>
          <div v-if="item.dropdown" class="p-0" :class="{ 'dropdown-menu': true, 'show': item.isOpen }">
              <div class="d-lg-flex">
                  <div v-if="item.image" class="mega-dropdown-column d-flex justify-content-center align-items-center rounded-3 rounded-end-0 px-0"
                      style="margin: -1px; background-color: #f3f6ff;">
                      <img :src="item.image" alt="Landings">
                  </div>
                  <div v-for="(section, sectionIndex) in item.sections" :key="sectionIndex" class="mega-dropdown-column pt-lg-3 pb-lg-4">
                      <ul class="list-unstyled mb-0">
                          <li v-if="section.sectionTitle" class="px-3 mb-2">{{ section.sectionTitle }}</li>
                          <li v-for="(link, linkIndex) in section.links" :key="linkIndex">
                              <router-link :to="link.url" class="dropdown-item d-flex align-items-center" @click="closeOffcanvas()">
                                  {{ link.title }}
                                  <span v-if="link.new" class="badge bg-success ms-2">New</span>
                              </router-link>
                          </li>
                      </ul>
                  </div>
              </div>a
          </div>
        </li>
      </ul>

      <!-- <input class="form-control form-control-sm w-auto" type="search" id="search-input" placeholder="Search..." /> -->

      <button type="button" class="navbar-toggler d-block d-lg-none ms-auto me-4" data-bs-toggle="offcanvas"
        data-bs-target="#docsNav" aria-controls="docsNav" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="form-check form-switch mode-switch pe-lg-1 ms-lg-auto" data-bs-toggle="mode">
        <input class="form-check-input" type="checkbox" id="theme-mode" :checked="isDarkMode" @change="toggleTheme">
        <!-- <label class="form-check-label d-none d-sm-block d-lg-none d-xl-block" for="theme-mode">{{ themeLabel }}</label> -->
      </div>


      <!-- <a :href="buyLink" class="btn btn-primary btn-sm fs-sm rounded ms-4 d-none d-lg-inline-flex" target="_blank"
        rel="noopener">
        <i class="bx bx-cart fs-5 lh-1 me-1"></i>
        &nbsp;Expore
      </a> -->
    </div>
  </div>
</header>
</template>


<script>
export default {
  props: {
    navItems: {
      type: Array,
      default: () => []
    }
  },
  methods:{
    toggleTheme() {
        this.isDarkMode = !this.isDarkMode;
        if (this.isDarkMode) {
          document.documentElement.setAttribute('data-bs-theme', 'dark');
          localStorage.setItem('theme', 'dark');
        } else {
          document.documentElement.setAttribute('data-bs-theme', 'light');
          localStorage.setItem('theme', 'light');
        }
      },
  },
  watch: {
    isDarkMode(newValue) {
      this.themeLabel = newValue ? "Dark" : "Light";
    }
  },
  created() {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme === 'dark') {
      this.isDarkMode = true;
    }else{
      this.isDarkMode = false;
    }
  }
};
</script>

<style scoped>
/* Add any necessary styles here */
</style>
