<template>
  <aside class="col-xl-3 col-lg-4">
    <div class="offcanvas-lg offcanvas-end border-1 bg-blur" id="blog-sidebar" tabindex="-1">

        <!-- Header -->
        <div class="offcanvas-header border-bottom">
            <h3 class="offcanvas-title fs-lg">Sidebar</h3>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas"
                data-bs-target="#blog-sidebar" aria-label="Close"></button>
        </div>

        <!-- Body -->
        <div class="offcanvas-body">

            <!-- Search form -->
            <form class="input-group mb-4">
                <input type="text" placeholder="Search the blog..." class="form-control rounded pe-5">
                <i
                    class="bx bx-search position-absolute top-50 end-0 translate-middle-y me-3 fs-lg zindex-5"></i>
            </form>

            <div class="card card-body mb-4">
                <h3 class="h5">Admin</h3>
                <ul class="nav flex-column fs-sm">
                    <li class="nav-item mb-1">
                        <router-link to="/blog/add" class="nav-link py-1 px-0">Add blog post</router-link>
                    </li>
                    <li class="nav-item mb-1">
                        <router-link to="/blog/manager/categories" class="nav-link py-1 px-0">Category Manager</router-link>
                    </li>
                    <li class="nav-item mb-1">
                        <router-link to="/blog/manager/tags" class="nav-link py-1 px-0">Tags Manager</router-link>
                    </li>
                    <li class="nav-item mb-1">
                        <router-link to="/blog/manager/posts" class="nav-link py-1 px-0">Posts Manager</router-link>
                    </li>
                </ul>
            </div>

            <!-- Categories -->
            <div class="card card-body mb-4">
                <h3 class="h5">Categories</h3>
                <ul class="nav flex-column fs-sm">
                    <li class="nav-item" v-for="cat in categories" :key="cat.id">
                        <router-link :to="category_route(cat.name)" class="nav-link py-1 px-0">{{ cat.name }}
                            <span class="fw-normal opacity-60 ms-1">({{cat.blog_count}})</span>
                        </router-link>
                    </li>
                </ul>
            </div>

            <!-- Popular posts -->
            <div class="card card-body border-0 position-relative mb-4" v-if="showPopularPosts">
                <span
                    class="position-absolute top-0 start-0 w-100 h-100 bg-gradient-primary opacity-10 rounded-3"></span>
                <div class="position-relative zindex-2">
                    <h3 class="h5">Popular posts</h3>
                    <ul class="list-unstyled mb-0">
                        <li class="border-bottom pb-3 mb-3">
                            <h4 class="h6 mb-2">
                                <a href="#">5 Bad Landing Page Examples &amp; How We Would Fix Them</a>
                            </h4>
                            <div class="d-flex align-items-center text-muted pt-1">
                                <div class="fs-xs border-end pe-3 me-3">12 hours ago</div>
                                <div class="d-flex align-items-center me-3">
                                    <i class="bx bx-like fs-base me-1"></i>
                                    <span class="fs-xs">8</span>
                                </div>
                                <div class="d-flex align-items-center me-3">
                                    <i class="bx bx-comment fs-base me-1"></i>
                                    <span class="fs-xs">4</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="bx bx-share-alt fs-base me-1"></i>
                                    <span class="fs-xs">2</span>
                                </div>
                            </div>
                        </li>
                        <li class="border-bottom pb-3 mb-3">
                            <h4 class="h6 mb-2">
                                <a href="#">How Agile is Your Forecasting Process?</a>
                            </h4>
                            <div class="d-flex align-items-center text-muted pt-1">
                                <div class="fs-xs border-end pe-3 me-3">Oct 9, 2023</div>
                                <div class="d-flex align-items-center me-3">
                                    <i class="bx bx-like fs-base me-1"></i>
                                    <span class="fs-xs">4</span>
                                </div>
                                <div class="d-flex align-items-center me-3">
                                    <i class="bx bx-comment fs-base me-1"></i>
                                    <span class="fs-xs">1</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="bx bx-share-alt fs-base me-1"></i>
                                    <span class="fs-xs">0</span>
                                </div>
                            </div>
                        </li>
                        <li>
                            <h4 class="h6 mb-2">
                                <a href="#">Inclusive Marketing: Why and How Does it Work?</a>
                            </h4>
                            <div class="d-flex align-items-center text-muted pt-1">
                                <div class="fs-xs border-end pe-3 me-3">Sep 13, 2023</div>
                                <div class="d-flex align-items-center me-3">
                                    <i class="bx bx-like fs-base me-1"></i>
                                    <span class="fs-xs">5</span>
                                </div>
                                <div class="d-flex align-items-center me-3">
                                    <i class="bx bx-comment fs-base me-1"></i>
                                    <span class="fs-xs">2</span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <i class="bx bx-share-alt fs-base me-1"></i>
                                    <span class="fs-xs">4</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- Tags -->
            <div class="card card-body mb-4">
                <h3 class="h5">Tags</h3>
                <div class="d-flex flex-wrap">
                    <router-link v-for="tag in tags" :key="tag.id" :to="`/blog/tag/${tag.name.toLowerCase()}`" class="btn btn-outline-secondary btn-sm px-3 my-1 me-2">#{{ tag.name }}</router-link>
                </div>
                
            </div>

            <!-- Follow Us -->
            <div class="card mb-4" v-if="showFollowUs">
                <div class="card-body">
                    <h5 class="mb-4">Follow Us</h5>
                    <a href="#" class="btn btn-icon btn-sm btn-secondary btn-linkedin me-2 mb-2"
                        aria-label="LinkedIn">
                        <i class="bx bxl-linkedin"></i>
                    </a>
                    <a href="#" class="btn btn-icon btn-sm btn-secondary btn-facebook me-2 mb-2"
                        aria-label="Facebook">
                        <i class="bx bxl-facebook"></i>
                    </a>
                    <a href="#" class="btn btn-icon btn-sm btn-secondary btn-twitter me-2 mb-2"
                        aria-label="Twitter">
                        <i class="bx bxl-twitter"></i>
                    </a>
                    <a href="#" class="btn btn-icon btn-sm btn-secondary btn-instagram me-2 mb-2"
                        aria-label="Instagram">
                        <i class="bx bxl-instagram"></i>
                    </a>
                </div>
            </div>

            <!-- Advertising -->
            <div
                class="card border-0 bg-faded-primary bg-repeat-0 bg-size-cover"
                :style="{
                'min-height': '25rem',
                'background-image': 'url(' + require('@/assets/img/blog/banner.png') + ')'
                }"
                v-if="showAdBanner"
            >
                <div class="card-body">
                <h5 class="h3 mb-4 pb-2 text-center">Ad Banner</h5>
                </div>
            </div>
        </div>
    </div>
</aside>
</template>

<script>
export default {
    data(){
        return {
            showPopularPosts: false,
            showFollowUs: false,
            showAdBanner: true,
        }
    },
    methods:{
        category_route(cat){
            return `/blog/category/${cat.toLowerCase()}`
        },

    },
    computed: {
        categories() {
            return this.$store.getters.getCategories;
        },
        tags() {
            return this.$store.getters.getTags;
        },
        
    },
    created() {
        // Fetch categories when the component is created
        this.$store.dispatch('fetchCategories');
        this.$store.dispatch('fetchTags');
    },
}
</script>

<style>

</style>