<template>
    <NavBarVue v-if="!isPhrank"></NavBarVue>
    
    <main :class="{'pt-4 mt-4': !isHomepage && !isScrollToAbyss && !isSolarSystem && !isPhrank, 'winupdate': isPhrank}">
      <router-view />
    </main>
    <!-- <FooterBlock></FooterBlock> -->
  </template>
  
  <script>
  // import FooterBlock from "../components/FooterBlock.vue";
  import NavBarVue from "../components/NavbarDefault.vue";
  
  export default {
    name: 'App',
    components: {
      NavBarVue,
      // FooterBlock,
    },
    computed: {
      isHomepage() {
        return this.$route.path === '/';
      },
      isPhrank() {
        return  this.$route.path === '/windows-update' || 
                this.$route.path === '/windows-blue-death' ||
                this.$route.path === '/matrix-screen'
                ;
      },
      isScrollToAbyss() {
        return this.$route.path === '/scroll-to-the-abyss'; 
      },
      isSolarSystem() {
        return this.$route.path === '/solar-system'; 
      },
      
    }
  };
  </script>
  
  
<style>
.winupdate{
  /* background: #000 !important; */
}
</style>
  