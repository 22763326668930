<template>
  <aside class="position-absolute" style="z-index: 11;">
    <div id="docsNav" class="offcanvas-lg offcanvas-start d-flex flex-column position-fixed top-0 start-0 vh-100 border-end-lg bg-blur border-1" style="width: 19rem; overflow-y: scroll;">
      
      
      <div class="offcanvas-header d-block d-lg-none border-bottom">
        <div class="d-flex align-items-center justify-content-between mb-3">
          <h5 class="d-lg-none mb-0">Menu</h5>
          <button type="button" class="btn-close d-lg-none" data-bs-dismiss="offcanvas" data-bs-target="#docsNav" aria-label="Close"></button>
        </div>
        
      </div>


      <div class="offcanvas-body p-4 ">
        <h3 class="fs-lg d-lg-none ">
          Menu
        </h3>
        <div class="list-group list-group-flush mx-n4 d-flex d-lg-none mb-3">
          <router-link v-for="item in navItems" :to="item.url" class="list-group-item list-group-item-action d-flex align-items-center border-0 rounded-0 py-2 px-4" :key="item.label">
            <i :class="item.iconClass"></i>
            {{ item.title }}
          </router-link>
        </div>
        <h3 class="fs-lg d-flex justify-content-between">
          Contents
          <span class="badge border border-primary text-primary">{{ contents.length }}</span>
        </h3>
        <ul class="list-group list-group-flush mx-n4 pb-2" >
          <li class="nav-item" v-for="(content, index) in contents" :key="index">
            <router-link  class="list-group-item list-group-item-action border-0 py-2 px-4 d-flex justify-content-between" :to="content.link" :class="{ 'disabled': content.missing, 'mb-0':content.dropdown != null }" >
              {{ index }} -
              {{ content.label }}
              <span v-if="content.new" class="badge bg-info bg-opacity-10 text-info fs-xs mb-2">New</span>
              <span v-if="content.missing" class="badge bg-danger bg-opacity-10 text-danger fs-xs mb-2">Missing</span>
              <span v-if="content.broken" class="badge bg-warning bg-opacity-10 text-warning fs-xs mb-2">Broken</span>
              <span v-if="content.perfect" class="badge bg-primary bg-opacity-10 text-primary fs-xs mb-2">Perfect</span>
            </router-link>

            
            <div class="dropdown list-group list-group-flush ps-4" v-if="content.dropdown != null && $route.path === content.link">
              <router-link :to="link.link" v-for="link in content.dropdown" :key="link.label"
              :class="{
                'active': $route.hash === ('#' + (link.link.split('#')[1] || '')),
              }"
              :exact-active-class="''"
              class="list-group-item list-group-item-action py-1 my-0 fw-normal w-auto border-start" >{{link.label}}</router-link>
            </div>


          </li>
        </ul>
      </div>
      <div class="offcanvas-header border-top">
        <router-link to="/login" class="btn btn-primary w-100">
          <i class="bx bx-user fs-4 lh-1 me-1"></i>Login
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    navItems: {
      type: Array,
      default: () => []
    },
    contents: {
      type: Array,
      default: () => []
    },
    data() {
      return {
        currentHash: this.$route.hash, // Initialize with the current hash
      };
    },
  }
};
</script>

<style scoped>
.dropdown .router-link-active,
.dropdown .router-link-exact-active {
  background-color: transparent !important;
  color: inherit !important;
}

</style>
