import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import process from 'process';

// import 'bootstrap/dist/css/bootstrap.css'

import 'swiper/css';
import 'swiper/css/pagination';


// import 'fullcalendar/dist/css/'
// import './assets/css/around-icons.min.css';
import './assets/css/boxicons.min.css';
import 'bootstrap';

import 'jquery/src/jquery.js';
// import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'popper.js/dist/popper.min.js';
// import 'fullcalendar/index.js';

// import './assets/js/theme.js';
// import './assets/js/theme_script_section.js';

import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';


import "prismjs/themes/prism.css";
import "prismjs/plugins/toolbar/prism-toolbar.css";
import "prismjs/plugins/line-numbers/prism-line-numbers.css";

import 'lightgallery/css/lightgallery.css'
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-video.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-zoom.css';

// import "@/assets/scss/main.scss";

import './assets/css/theme.css';

// Initialize theme from localStorage
const storedTheme = localStorage.getItem('theme');
if (storedTheme) {
  document.documentElement.setAttribute('data-bs-theme', storedTheme);
}

// import VueMasonry from 'vue-masonry-css'

import { VueMasonryPlugin } from "vue-masonry";

createApp(App)
  .use(store)
  .use(router)
  .use(process)
  .use( CkeditorPlugin )
  .use(VueMasonryPlugin)
  .mount('#app')
