<template>

    <NavBarVue :nav-items="navItems"></NavBarVue>
    <AsideMenu :nav-items="navItems" :contents="contents"></AsideMenu>

    
    <main class="docs-container pt-5">
      <div class="container-fluid">

        <router-view />

      </div>
    </main>

  </template>
  
  <script>
  import AsideMenu from "@/components/AsideMenu.vue";
  import NavBarVue from "../components/NavbarDocs.vue";
  
  export default {
    name: 'App',
    components: {
      NavBarVue,
      AsideMenu,
    },
    data() {
      return {
        logoSrc: "../assets/logo.png",
        brandName: "D1m",
        badgeText: "Docs",
        buyLink: "/",
        navItems: [
          {
              title: 'Home',
              dropdown: false,
              url: '/'
          },
          
          {
              title: 'Information',
              dropdown: true,
              sections: [
                  {
                      sectionTitle: 'Useful & Interesting stuff',
                      links:[
                          { title: 'Bookmarks', url: '/bookmarks' },
                          { title: 'Great Minds', url: '/great-minds' },
                          { title: 'Quotes', url: '/quotes' },
                          { title: 'Human History', url: '/human-history' },
                          { title: 'Scroll to the abyss', url: '/scroll-to-the-abyss' },
                          { title: 'Solar System', url: '/solar-system' },
                          
                          
                      ],
                  },
                  {
                      sectionTitle: 'Calculators',
                      links:[
                          


                          { title: 'Sleep calculator', url: '/sleep-calculator' },
                          { title: 'Saving Calculator', url: '/saving-calculator' },
                          { title: 'BMI Calculator', url: '/bmi-calculator' },

                          { title: 'Flash Cards', url: '/flash-cards' },
                          { title: 'Hourglass', url: '/hourglass' },

                          { title: 'Particle Sphere', url: '/particle-sphere' },
                          { title: 'Window Designer', url: '/window-designer' },


                      ],
                  },
                  {
                      links:[
                          { title: 'Graphs', url: '/graphs' },
                          { title: 'Rubix Cube Algorithms', url: '/rubix-cube-algorithms' },
                          { title: 'Rubix Cube Stopwatch', url: '/rubix-cube-stopwatch' },
                      ],
                  },
              ],
          },
          {
              title: 'Games',
              dropdown: true,
              sections: [
                  {
                      sectionTitle: 'Logic & Thinking',
                      links:[
                          // { title: 'Logic',},
                          { title: 'Catch the thief', url: '/thief' },
                          { title: 'Cabbage, Sheep and Wolf', url: '/cabbage-sheep-wolf' },
                          { title: 'Sudoku', url: '/sudoku' },
                          { title: 'Crack the code', url: '/crack-the-code' },
                          { title: 'Sequence Memory', url: '/sequence-memory' },
                          { title: 'Reaction Time', url: '/games/reaction-time' },
                          { title: 'Aim trainer', url: '/games/aim-trainer' },
                          { title: 'Memory Game', url: '/memory-game' },
                          { title: 'Speed typing', url: '/speed-typing' },
                          

                      ],
                  },
                  {
                      sectionTitle: 'Luck & Chance',
                      links:[

                          { title: 'Slot Machine', url: '/slot-machine' },
                          { title: 'Roulette', url: '/roulette' },
                          { title: 'Black Jack', url: '/black-jack',new: true },
                          { title: 'World Map', url: '/world-map' },

                          { title: 'Language Game', url: '/language-game' },

                          { title: 'Rock Paper Scissors', dropdown: false, url: '/rps/create',},
                          { title: 'Jigsaw Puzzle', url: '/games/jigsaw-puzzle' },
                      ],
                  },
              ],
          },
          {
              title: 'Developer',
              dropdown: true,
              sections: [
                  {
                      links:[

                          { title: 'ADT Time Complexity', url: '/adt-time-complexity' },
                          { title: 'Binary Search Tree', url: '/binary-search-tree' },
                          { title: 'Graphs & Trees', url: '/ads/graphs' },
                          { title: 'Sorting Algorithms', url: '/sorting-algorithms' },
                          { title: 'CSS Backgrounds', url: '/css-backgrounds' },
                          { title: 'HTTP Status Codes', url: '/http-status-codes' },
                          { title: 'Linux Commands', url: '/linux-commands' },
                          
                          { title: 'Bootstrap Cheatsheet', url: '/bootstrap-cheatsheet' },
                          { title: 'Tmux Cheatsheet', url: '/tmux-cheatsheet' },
                          { title: 'Nginx Cheatsheet', url: '/nginx-cheatsheet' },

                      ],
                  },
                  {
                      links:[
                          { title: 'Developer Checklist', url: '/developer-checklist' },
                          { title: 'Minifier', url: '/minifier' },
                          { title: 'Image to base64', url: '/img-to-base64' },
                          { title: 'CSS Glassmorophism', url: '/css-generator' },
                          
                          { title: 'XOR Cipher', url: '/xor-cipher' },
                          { title: 'Caesar Cipher', url: '/caesar-cipher' },

                          { title: 'Binary Operations', url: '/binary-operations' },

                      ],
                  },
                  
              ],
          },
          {
              title: 'Hidden',
              dropdown: true,
              requiresAuth: true,
              sections: [
                  {
                      links:[

                          { title: 'Message Faker', url: '/chat-ui' },
                          

                      ],
                  },
                  
              ],
          },
          {
              title: 'Blog',
              dropdown: false,
              url: '/blog'
          },
          {
              title: 'UI Kit',
              dropdown: false,
              url: '/ui_kit/typography'
          },
        ],
        // [
        //   { label: "Live preview", link: "/", iconClass: "bx bx-desktop opacity-70 fs-lg me-1" },
        //   // { label: "UI Kit (Components)", link: "/ui_kit/typography", iconClass: "bx bx-layer opacity-70 fs-lg me-1" }
        // ],
        contents: [
          {
            label: "Typography",
            link: "/ui_kit/typography",
            // perfect: true,
            dropdown: [
              { label: "Headings", link: "/ui_kit/typography#headings" },
              { label: "Display settings", link: "/ui_kit/typography#display-settings" },
              { label: "Body text sizes", link: "/ui_kit/typography#body-text" },
              { label: "Inline text elements", link: "/ui_kit/typography#inline-text" },
              { label: "Abbreviations", link: "/ui_kit/typography#abbreviations" },
              { label: "Blockquote", link: "/ui_kit/typography#blockquote" },
              { label: "Lists", link: "/ui_kit/typography#lists" },
              { label: "Lists: Unstyled", link: "/ui_kit/typography#lists-unstyled" },
              { label: "Lists: Inline", link: "/ui_kit/typography#lists-inline" },
              { label: "Description: Basic", link: "/ui_kit/typography#description-list-basic" },
              { label: "Description: alignment", link: "/ui_kit/typography#description-list-alignment" },
            ],
            
          },
          {
            label: "Accordion",
            link: "/ui_kit/accordion",
            // perfect: true,
            dropdown: [
              { label: "Default style", link: "/ui_kit/accordion#default" },
              { label: "Alternative style", link: "/ui_kit/accordion#alternative" },
            ],
          }, {
            label: "Alerts",
            link: "/ui_kit/alerts",
            dropdown: [
              { label: "Basic Example", link: "/ui_kit/alerts#basic" },
              { label: "Alert with icon", link: "/ui_kit/alerts#with-icon" },
              { label: "Dismissible Alerts", link: "/ui_kit/alerts#dismissible" },
              { label: "Additional Content", link: "/ui_kit/alerts#additional" },
            ],
          }, {
            label: "Audio Player",
            link: "/ui_kit/audio-player",
            // missing: true,
            dropdown: [
              { label: "Simple Audio Player", link: "/ui_kit/audio-player#default" },
              { label: "Audio Player with background", link: "/ui_kit/audio-player#alternative" },
            ],
          }, {
            label: "Badges",
            link: "/ui_kit/badges",
            dropdown: [
              { label: 'Solid', link: '#badges-solid' },
              { label: 'Outline', link: '#badges-outline' },
              { label: 'Faded', link: '#badges-faded' },
              { label: 'Badges with Shadow', link: '#badges-shadow' },
              { label: 'Pill badges', link: '#pill-badges' },
              { label: 'Inside heading', link: '#badges-heading' },
              { label: 'Inside button', link: '#badges-button' },
              { label: 'Inside list group', link: '#badges-list-group' }
            ]
          },
          {
            label: "Borders",
            link: "/ui_kit/borders",
            // broken: true,
            missing: true,
            dropdown: [
              { label: 'Solid', link: '#border-solid' },
              // { label: 'Outline', link: '#badges-outline' },
              // { label: 'Faded', link: '#badges-faded' },
              // { label: 'Badges with Shadow', link: '#badges-shadow' },
              // { label: 'Pill badges', link: '#pill-badges' },
              // { label: 'Inside heading', link: '#badges-heading' },
              // { label: 'Inside button', link: '#badges-button' },
              // { label: 'Inside list group', link: '#badges-list-group' }
              
              /**
               * add the animated border from /get-sprite-xy
               */
            ]
          },
          {
            label: "Blog Components",
            link: "/ui_kit/blog-components",
            dropdown: [
              { label: 'Post preview (Grid)', link: '#blog-card-vertical' },
              { label: 'Post preview (List)', link: '#blog-card-horizontal' },
              { label: 'Alt Post preview (Grid)', link: '#blog-card-vertical-alt' },
              { label: 'Alt Post preview (List)', link: '#blog-card-horizontal-alt' },
              { label: 'Color background on hover', link: '#blog-card-hover-bg' },
              { label: 'Podcast preview card', link: '#blog-podcast' },
              { label: 'Simple feed', link: '#blog-simple-feed' },
              { label: 'Categories widget', link: '#blog-categories-widget' },
              { label: 'Popular / Recent posts widget', link: '#blog-posts-widget' },
              { label: 'Tags widget', link: '#blog-tags-widget' },
              { label: 'Post comments', link: '#blog-comments' }
            ]
          }, {
            label: "Breadcrumb",
            link: "/ui_kit/breadcrumbs",
          }, {
            label: "Buttons",
            link: "/ui_kit/buttons",
            dropdown: [
              { label: "Solid", link: "#buttons-solid" },
              { label: "Outline", link: "#buttons-outline" },
              { label: "Shadow", link: "#buttons-shadow" },
              { label: "Gradient", link: "#buttons-gradient" },
              { label: "Icons", link: "#buttons-icons" },
              { label: "Market buttons", link: "#buttons-market" },
              { label: "Shapes", link: "#buttons-shapes" },
              { label: "Sizes", link: "#buttons-sizes" },
              { label: "States", link: "#buttons-states" },
            ],
          }, {
            label: "Button Group",
            link: "/ui_kit/button-group",
            dropdown: [
              { label: "Basic example", link: "#btn-group-basic" },
              { label: "Button toolbar", link: "#btn-group-toolbar" },
              { label: "Nested dropdown", link: "#btn-group-dropdown" },
              { label: "Sizing", link: "#btn-group-sizing" }
            ]
          }, {
            label: "Cards",
            link: "/ui_kit/cards",
            missing: false,
            dropdown: [
              { label: "Basic example", link: "#cards-basic" },
              { label: "Alternative style", link: "#cards-alt" },
              { label: "Hover effect", link: "#cards-hover" },
              { label: "Image top/bottom", link: "#cards-image-position" },
              { label: "Horizontal layout", link: "#cards-horizontal" },
              { label: "Header and footer", link: "#cards-header-footer" },
              { label: "Cards text alignment", link: "#cards-text-alignment" },
              { label: "Navigation Tabs", link: "#cards-nav-tabs" },
              { label: "Alternative Navigation Tabs", link: "#cards-nav-tabs-alt" },
              { label: "Navigation Pills", link: "#cards-nav-pills" },
              { label: "List group inside card", link: "#cards-list-group" },
              { label: "Card style: Solid background", link: "#cards-bg-solid" },
              { label: "Card style: Faded background", link: "#cards-bg-faded" },
              { label: "Card style: Border color", link: "#cards-border-color" },
              { label: "Card group", link: "#cards-group" },
            ]
          }, {
            label: "Carousel (Slider)",
            link: "/ui_kit/carousel",
            dropdown: [
              { label: "Navigation (Prev / next buttons)", link: "#carousel-navigation" },
              { label: "Pagination", link: "#carousel-pagination" },
              { label: "Multiple slides (Responsive) + Controls and pagination outside", link: "#carousel-multiple-slides" },
              { label: "Link slides to outside content", link: "#carousel-link-to-content" },
              { label: "Fade transition", link: "#carousel-fade" },
              { label: "Thumbnails", link: "#carousel-thumbnails" },
            ]
          }, {
            label: "Charts",
            link: "/ui_kit/charts",
            // broken: true,
            dropdown: [
              { label: "Line chart", link: "#chart-line" },
              { label: "Bar chart", link: "#chart-bar" },
              { label: "Pie chart", link: "#chart-pie" },
              { label: "Doughnut chart", link: "#chart-doughnut" },
              { label: "Polar area chart", link: "#chart-polar" },
              { label: "Radar chart", link: "#chart-radar" },
            ],
          }, {
            label: "Code",
            link: "/ui_kit/code",
            broken: true,
            dropdown: [
              { label: "Inline code example", link: "#code-inline" },
              { label: "User input", link: "#code-user-input" },
              { label: "Math & Variables", link: "#code-math" },
              { label: "Code block", link: "#code-block" },
              { label: "Code block scrollable", link: "#code-block-scrollable" },
              { label: "Code highlighting", link: "#code-highlighting" },
            ],
          }, {
            label: "Collapse",
            link: "/ui_kit/collapse",
            dropdown: [
              { label: "Basic example", link: "#collapse-basic" },
              { label: "Horizontal", link: "#collapse-horizontal" },
              { label: "Multiple targets", link: "#collapse-multiple" }
            ],
          }, {
            label: "Countdown",
            link: "/ui_kit/countdown",
            // broken: true,
            dropdown: [
              { label: "Basic example", link: "#countdown-basic" },
              { label: "Display no years", link: "#countdown-no-years" },
              { label: "Sizing", link: "#countdown-sizing" },
              { label: "Colors", link: "#countdown-colors" },
              { label: "Cards example", link: "#countdown-cards" }
            ],
          }, {
            label: "Dropdowns",
            link: "/ui_kit/dropdowns",
            dropdown: [
              { label: "Static examples", link: "#dropdowns-static" },
              { label: "Dark dropdowns", link: "#dropdowns-dark" },
              { label: "Forms inside dropdown", link: "#dropdowns-forms" },
              { label: "Directions", link: "#dropdowns-directions" },
              { label: "Menu alignment", link: "#dropdowns-alignment" },
              { label: "Split button", link: "#dropdowns-split-button" }
            ],
          }, {
            label: "Event Calendar",
            link: "/ui_kit/event-calendar",
            // broken: true,
            dropdown: [],
          }, {
            label: "Forms",
            link: "/ui_kit/forms",
            dropdown: [
              { label: "Supported input types", link: "#forms-types" },
              { label: "Floating labels", link: "#forms-floating-labels" },
              { label: "Password visibility toggle", link: "#forms-password-toggle" },
              { label: "Checkboxes", link: "#forms-checkboxes" },
              { label: "Radio buttons", link: "#forms-radios" },
              { label: "Switches", link: "#forms-switches" },
              { label: "Theme mode switch", link: "#forms-mode-switch" },
              { label: "Button toggles (Color, size options)", link: "#forms-btns" },
              { label: "Count input (+/-)", link: "#forms-count" },
              { label: "Range slider", link: "#forms-range-slider" },
              { label: "Sizing", link: "#forms-sizing" },
              { label: "Readonly & Disabled", link: "#forms-disabled" },
              { label: "Inline form", link: "#forms-inline" },
              { label: "Help text", link: "#forms-help-text" },
              { label: "Validation: status text", link: "#forms-validation-text" },
              { label: "Validation: status tooltips", link: "#forms-validation-tooltips" },
              { label: "Input mask (Formatted content)", link: "#forms-formatted" },
            ],
          }, {
            label: "Gallery (Lightbox)",
            link: "/ui_kit/gallery",
            missing: false,
            // broken: true,
            dropdown: [
              { label: "Media: Image", link: "#gallery-image" },
              { label: "Media: Video", link: "#gallery-video" },
              { label: "Media: Iframe (Google map)", link: "#gallery-iframe" },
              { label: "Zoom effect", link: "#gallery-zoom" },
              { label: "Thumbnails", link: "#gallery-thumbnails" },
              { label: "Inside card", link: "#gallery-card" }
            ],
          }, {
            label: "Hotspots",
            link: "/ui_kit/hotspots",
            missing: false,
            broken: true,
            dropdown: [
              { label: "Style 1", link: "#hotspots-style-1" },
              { label: "Style 2", link: "#hotspots-style-2" }
            ],
          }, {
            label: "Icons",
            link: "/ui_kit/icon-font",
            dropdown: [],
          }, {
            label: "Images",
            link: "/ui_kit/images",
            dropdown: [
              { link: "#image-shapes", label: "Image shapes" },
              { link: "#image-thumbnails", label: "Thumbnails" },
              { link: "#image-figures", label: "Figures with caption" },
              { link: "#image-swap", label: "Image swap on hover" }
            ],
          }, {
            label: "Image Comparison Slider",
            link: "/ui_kit/image-comparison-slider",
            dropdown: [],
          }, {
            label: "Input Group",
            link: "/ui_kit/input-group",
            dropdown: [
              { label: "Addon position", link: "#ig-addon-position" },
              { label: "Multiple addons", link: "#ig-addon-multiple" },
              { label: "Different types", link: "#ig-addon-types" },
              { label: "Multiple inputs", link: "#ig-multiple-inputs" },
              { label: "Button addons", link: "#ig-addon-button" },
              { label: "Pill shape", link: "#ig-pill" },
              { label: "Sizing", link: "#ig-sizing" }
            ],
          }, {
            label: "List Group",
            link: "/ui_kit/list-group",
            dropdown: [
              { link: "#lg-basic", label: "Basic example" },
              { link: "#lg-links", label: "Links or buttons" },
              { link: "#lg-flush", label: "Flush" },
              { link: "#lg-icons", label: "With icons & badges" },
              { link: "#lg-checkboxes", label: "Checkboxes & radios" },
              { link: "#lg-numbered", label: "Numbered list group" },
              { link: "#lg-horizontal", label: "Horizontal list group" },
              { link: "#lg-horizontal-action", label: "Actionable horizontal list group" },
              { link: "#lg-custom-content", label: "Custom content" },
              { link: "#lg-javascript", label: "JavaScript behavior" }
            ],
          }, {
            label: "Maps",
            link: "/ui_kit/maps",
            broken: true,
            dropdown: [
              { link: "#map-basic", label: "Basic example (no options passed)" },
              { link: "#map-options", label: "Custom options" },
              { link: "#map-style", label: "Alternative map style" }
            ],
          }, {
            label: "Modal",
            link: "/ui_kit/modal",
            dropdown: [
              { label: "Modal markup", link: "#modal-markup" },
              { label: "Tabs and forms", link: "#modal-tabs-forms" },
              { label: "Live demo (variants)", link: "#modal-demo" }
            ],
          }, {
            label: "Navbar",
            link: "/ui_kit/navbar",
            dropdown: [
              { label: "Supported content", link: "#navbar-content" },
              { label: "Color schemes", link: "#navbar-colors" },
              { label: "Responsive offcanvas menu", link: "#navbar-offcanvas" }
            ],
          }, {
            label: "Offcanvas",
            link: "/ui_kit/offcanvas",
            dropdown: [
              { label: "Offcanvas components", link: "#offcanvas-components" },
              { label: "Placement", link: "#offcanvas-placement" },
              { label: "Mobile menu offcanvas (responsive)", link: "#offcanvas-mobile" }
            ],
          }, {
            label: "Pagination",
            link: "/ui_kit/pagination",
            dropdown: [
              { label: "Basic example", link: "#pagination-basic" },
              { label: "With icons", link: "#pagination-icons" },
              { label: "Sizing", link: "#pagination-sizing" },
              { label: "Items per page selector", link: "#pagination-items" }
            ],
          }, {
            label: "Parallax",
            link: "/ui_kit/parallax",
            // broken: true,
            dropdown: [
              { label: "Background image", link: "#parallax-background" },
              { label: "Cursor position parallax", link: "#parallax-cursor" }
            ],
          }, {
            label: "Pills",
            link: "/ui_kit/pills",
            dropdown: [
              { label: "Basic example", link: "#pills-basic" },
              { label: "Rounded pills", link: "#pills-rounded" },
              { label: "Fill and justify", link: "#pills-fill" },
              { label: "Pills with dropdowns", link: "#pills-dropdown" },
              { label: "Vertical pills", link: "#pills-vertical" },
              { label: "Tabs-like behavior", link: "#pills-tabs" }
            ],
          }, {
            label: "Placeholders",
            link: "/ui_kit/placeholders",
            dropdown: [
              { label: "Width", link: "#placeholders-width" },
              { label: "Color", link: "#placeholders-color" },
              { label: "Sizing", link: "#placeholders-sizing" },
              { label: "Animation", link: "#placeholders-animation" },
              { label: "Use case example", link: "#placeholders-example" }
            ],
          }, {
            label: "Popovers",
            link: "/ui_kit/popovers",
            // broken: true,
            dropdown: [
              { label: "Static popovers", link: "#popovers-static" },
              { label: "Live demo", link: "#popovers-demo" },
              { label: "Toggle on click / hover", link: "#popovers-toggle" }
            ],
          }, {
            label: "Portfolio Components",
            link: "/ui_kit/portfolio-components",
            dropdown: [
              { label: "Grid view style 1", link: "#portfolio-grid-1" },
              { label: "Grid view style 2", link: "#portfolio-grid-2" },
              { label: "Grid view style 3", link: "#portfolio-grid-3" },
              { label: "List view style 1", link: "#portfolio-list-1" },
              { label: "List view style 2", link: "#portfolio-list-2" }
            ],
          }, {
            label: "Pricing",
            link: "/ui_kit/pricing",
            dropdown: [
              { label: "Cards: Style 1", link: "#pricing-cards-1" },
              { label: "Cards: Style 2", link: "#pricing-cards-2" },
              { label: "List", link: "#pricing-list" },
              { label: "Table", link: "#pricing-table" },
              { label: "Price switch", link: "#pricing-switch" }
            ],
          }, {
            label: "Progress Bars",
            link: "/ui_kit/progress",
            dropdown: [
              { label: "Color variation (thick)", link: "#progress-colors-thick" },
              { label: "Color variation (thin)", link: "#progress-colors-thin" },
              { label: "Multiple bars", link: "#progress-multiple" },
              { label: "Animated striped bars", link: "#progress-animated" },
              { label: "Use case: Rating breakdown", link: "#progress-rating-breakdown" }
            ],
          },
          {
            label: "Shop Components",
            link: "/ui_kit/shop-components",
            dropdown: [
              { label: "Product card", link: "#shop-product-card" },
              { label: "Tiny horizontal card", link: "#shop-horizontal-card" },
              { label: "Category card", link: "#shop-category-card" },
              { label: "Shopping cart item", link: "#shop-cart-item" },
              { label: "Categories filter", link: "#shop-categories-filter" },
              { label: "Price range slider", link: "#shop-price-range" },
              { label: "Color filter (custom checkboxes)", link: "#shop-color-filter" }
            ],
          },
          {
            label: "Social Buttons",
            link: "/ui_kit/social-buttons",
            dropdown: [
              { label: "Solid buttons", link: "#sb-solid" },
              { label: "Outline buttons", link: "#sb-outline" },
              { label: "Shape", link: "#sb-shape" },
              { label: "Size", link: "#sb-size" }
            ],
          },
           {
            label: "Spinners",
            link: "/ui_kit/spinners",
            dropdown: [
              { label: "Border spinner", link: "#spinners-border" },
              { label: "Growing spinner", link: "#spinners-growing" },
              { label: "Size", link: "#spinners-size" },
              { label: "Inside button", link: "#spinners-button" }
            ],
          }, {
            label: "Steps",
            link: "/ui_kit/steps",
            dropdown: [
              { label: "Horizontal layout", link: "#steps-horizontal" },
              { label: "Animate on hover", link: "#steps-hover" },
              { label: "Alignment", link: "#steps-alignment" },
              { label: "Vertical layout", link: "#steps-vertical" },
              { label: "No connector", link: "#steps-no-connector" },
              { label: "Card style", link: "#steps-cards" },
              { label: "Icons instead of numbers", link: "#steps-icons" }
            ],
          }, {
            label: "Tabs",
            link: "/ui_kit/tabs",
            dropdown: [
              { label: "With icons and dropdown", link: "#tabs-icon-dropdown" },
              { label: "Pill shape", link: "#tabs-pill-shape" },
              { label: "Alignment and fill", link: "#tabs-alignment" },
              { label: "Vertical tabs", link: "#tabs-vertical" },
              { label: "Image tabs", link: "#tabs-image" }
            ],
          }, {
            label: "Tables",
            link: "/ui_kit/tables",
            dropdown: [
              { link: "#tables-basic", label: "Basic example" },
              { link: "#tables-dark", label: "Dark table" },
              { link: "#tables-striped-rows", label: "Striped rows" },
              { link: "#tables-striped-columns", label: "Striped columns" },
              { link: "#tables-bordered", label: "Bordered table" },
              { link: "#tables-hoverable", label: "Hoverable rows" },
              { link: "#tables-contextual", label: "Contextual colors" },
              { link: "#tables-color-borders", label: "Color borders" },
            ],
          }, {
            label: "Team",
            link: "/ui_kit/team",
            dropdown: [
              { label: "Style 1", link: "#team-1" },
              { label: "Style 2", link: "#team-2" },
              { label: "Style 3", link: "#team-3" },
              { label: "Style 4", link: "#team-4" }
            ],
          }, {
            label: "Testimonials / Reviews",
            link: "/ui_kit/testimonials",
            dropdown: [
              { label: "Quotation", link: "#quotation" },
              { label: "Testimonial: Style 1", link: "#testimonial-1" },
              { label: "Testimonial: Style 2", link: "#testimonial-2" },
              { label: "Testimonial: Style 3", link: "#testimonial-3" },
              { label: "Testimonial: Style 4", link: "#testimonial-4" },
              { label: "Review", link: "#review" },
              { label: "Testimonials slider: Style 1", link: "#testimonials-slider-1" },
              { label: "Testimonials slider: Style 2", link: "#testimonials-slider-2" }
            ],
          }, {
            label: "Toasts",
            link: "/ui_kit/toasts",
            dropdown: [
              { label: "Basic example", link: "#toast-basic" },
              { label: "Color variations", link: "#toast-colors" }
            ],
          }, {
            label: "Tooltips",
            link: "/ui_kit/tooltips",
            // broken: true,
            dropdown: [
              { label: "Static example", link: "#tooltips-static" },
              { label: "Live demo", link: "#tooltips-demo" }
            ],
          }, {
            label: "Video Popup",
            link: "/ui_kit/video-popup",
            broken: true,
            dropdown: [
              { label: "Play button", link: "#video-btn" },
              { label: "Types and colors", link: "#video-btn-types-colors" },
              { label: "Sizing", link: "#video-btn-sizing" },
              { label: "On top of the image", link: "#video-btn-image" },
              { label: "Inside card", link: "#video-btn-card" }
            ],
          }, {
            label: "Utilites",
            link: "/ui_kit/utilites",
            dropdown: [
              { label: "Faded background colors", link: "#bg-faded" },
              { label: "Translucent image overlay", link: "#image-overlay" },
              { label: "Gradient text", link: "#gradient-text" },
              { label: "Opacity", link: "#opacity" }
            ],
          },
        ]
      };
    },
  };
  </script>
  
  
  <style>
  </style>
  