<template>
    <div class="fullscreen-header text-center">
      <div ref="starsContainer" class="stars-container"></div>
      <div class="header-content p-5">
            
            <p class="lead">Hi hi, my name is Dima</p>
            <h1 class="my-0">& I create <span>{{ currentDevelopText }}</span></h1>
            <transition name="fade" mode="out-in">
                <p :key="currentQuote.text">“{{ currentQuote.text }}” <span>- {{ currentQuote.author }}</span></p>
            </transition>
            
    </div>
    <div class="scroll-to-explore">
        <span class="fs-lg">Let me show you,</span>
        <p class="scroll-label">Scroll to explore</p>
        <i class="bx bx-chevron-down scroll-icon"></i>
    </div>
    </div>
</template>

<script>
  export default {
    name: 'FullScreenHeader',
    data() {
        return {
            developing: ['Web Applications', 'Software', 'Games', 'Mobile Apps', 'APIs', 'E-Commerce Platforms', 'User Interfaces',],
            currentDevelopIndex: 0,
            currentDevelopText: '',
            isErasing: false,
            quotes: [
                { text: "Every problem has a solution. You just have to be creative enough to find it.", author: "Travis Kalanick" },
                { text: "It's not that I'm so smart, it's just that I stay with problems longer.", author: "Albert Einstein" },
                { text: "Problems are not stop signs, they are guidelines.", author: "Robert H. Schuller" },
                { text: "We cannot solve our problems with the same thinking we used when we created them.", author: "Albert Einstein" },
            ],
            currentQuoteIndex: Math.floor(Math.random() * 4),
        };
    },
    computed: {
        currentQuote() {
            return this.quotes[this.currentQuoteIndex];
        }
    },
    mounted() {
      this.generateStars(180);
      this.setupAnimation();
      this.startQuoteRotation();
      this.animateDevelopText();
    },
    methods: {
        generateStars(numberOfStars) {
            const container = this.$refs.starsContainer;

            for (let i = 0; i < numberOfStars; i++) {
                let star = document.createElement('div');
                star.style.position = 'absolute';
                star.style.background = `rgba(var(--si-body-color-rgb), 0.4)`; // Use color based on the current theme
                star.style.borderRadius = '50%';
                star.style.width = `${Math.random() * 2 + 1}px`;
                star.style.height = star.style.width;
                star.style.left = `${Math.random() * 100}%`;
                star.style.top = `${Math.random() * 100}%`;
                star.style.animation = `drift ${Math.random() * 30 + 30}s infinite linear`;

                container.appendChild(star);
            }
        },
        setupAnimation() {
            const styleSheet = document.createElement("style");
            styleSheet.type = "text/css";
            styleSheet.innerText = `
            @keyframes drift {
                0%, 100% { transform: translateY(0); }
                100% { transform: translateY(200px); }
            }
            `;
            document.head.appendChild(styleSheet);
        },
        startQuoteRotation() {
            setInterval(() => {
                this.currentQuoteIndex = (this.currentQuoteIndex + 1) % this.quotes.length;
            }, 10000);
        },
        animateDevelopText() {
            let i = 0;
            let isErasing = false;
            let text = this.developing[this.currentDevelopIndex];

            const updateText = () => {
                if (!isErasing) {
                    if (i < text.length) {
                        this.currentDevelopText += text.charAt(i);
                        i++;
                        setTimeout(updateText, 70); // Continue typing
                    } else {
                        setTimeout(() => { 
                            isErasing = true; 
                            i--; // Set i to end of text for erasing
                            updateText(); // Start erasing
                        }, 1000); // Delay before starting to erase
                    }
                } else {
                    if (i >= 0) {
                        this.currentDevelopText = text.substring(0, i);
                        i--;
                        setTimeout(updateText, 70); // Continue erasing
                    } else {
                        // Prepare to type the next word
                        this.currentDevelopIndex = (this.currentDevelopIndex + 1) % this.developing.length;
                        text = this.developing[this.currentDevelopIndex]; // Update the text
                        i = 0; // Reset index for new text
                        isErasing = false; // Switch mode back to typing
                        setTimeout(updateText, 150); // Delay before starting new word
                    }
                }
            };

            updateText(); // Start the process
        },
        //
    }
  }
</script>

<style scoped>
.fullscreen-header {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}
.fullscreen-header h1 {
    font-size: 2.5rem; 
    font-weight: bold;
    background-clip: text;
    color: transparent;
    display: inline-block;
    background-image: linear-gradient(9deg, var(--si-body-color), var(--si-primary));
    -webkit-background-clip: text;
    min-height: 200px;
}
[data-bs-theme="light"] .fullscreen-header {
    background: radial-gradient(circle at top right, rgba(var(--si-info-rgb), 0.8), rgba(var(--si-info-rgb), 0.1) 60%);
}
[data-bs-theme="dark"] .fullscreen-header {
    background: radial-gradient(circle at top right, rgba(var(--si-primary-rgb), 0.3), rgba(var(--si-dark-rgb), 0.4) 65%);
}
.header-content > p {
    margin-top: 20px;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
}
.fade-enter, .fade-leave-to{
    opacity: 0;
}
.scroll-to-explore {
    text-align: center;
    position: absolute;
    bottom: 20px;
    width: 100%;
    cursor: pointer;
}

.scroll-label {
    font-size: 16px;
    margin-bottom: 5px;
}

.scroll-icon {
    display: inline-block;
    animation: bounce 2s infinite;
}

@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

</style>
  